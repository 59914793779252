import { useEffect, useCallback, useState } from "react";
import { HashRouter as Router, Route, Link } from "react-router-dom";
import Home from "./views/Main";
import useMetamask from "./helpers/useMetamask";
import Navbar from "./components/Navbar";
import MyriaAssets from "./views/MyriaAssets";
import Erc721Withdrawals from "./views/Erc721Withdrawals";
import Wallet from "./views/WalletERC20";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./react-webgl_specifics/app.module.css";

// unity-react-webgl dependencies
import { Unity, useUnityContext } from "react-unity-webgl" ;

function App() {

  // note that if we call setState methods in App, App function will re-run again.
  // thus, use useEffect whenever possible 
  
  const {
    isConnected,
    starkKey,
    connectL2Wallet,
    walletAdrress,
    myriaClient,
  } = useMetamask();

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    takeScreenshot,
    unload,
  } = useUnityContext({
    loaderUrl: "/unitybuild/WebGLBuild.loader.js",
    dataUrl: "/unitybuild/WebGLBuild.data.unityweb",
    frameworkUrl: "/unitybuild/WebGLBuild.framework.js.unityweb",
    codeUrl: "/unitybuild/WebGLBuild.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const navbarItems = [
    {
      title: "Wallet ERC20",
      url: "/wallet-erc20",
    },
    {
      title: "Myria L2 Assets",
      url: "/myria-assets",
    }
  ];

  // ethAddress = Metamask Account Address, starkKey = Myria Wallet Address
  const handlePassMetamaskInfo = (ethAddress: string, starkKey: string) => {
    //setMetaMaskInfo(info);
    sendMessage("MyriaSDKManager", "External_OnHandleMetaMaskInfo", ethAddress + "," + starkKey);
    console.log("handlePassMetamaskInfo");
    //console.log("StarkKey: " + starkKey);
    //console.log("WalletAddress: " + ethAddress);
  };

  const handleFailedMetamaskInfo = () => {
    //setMetaMaskInfo(info);
    sendMessage("MyriaSDKManager", "External_OnHandleMetaMaskInfo");
    console.log("handleFailedMetamaskInfo");
    //console.log("StarkKey: " + starkKey);
    //console.log("WalletAddress: " + ethAddress);
  };

  const handleRequestMetaMaskAuthentication = () => {
    // Assuming handlePassMetamaskInfo is a function that handles MetaMask info
    // Replace `ethAddress` and `starkKey` with actual data obtained from MetaMask
    console.log("connectL2Wallet");
    connectL2Wallet(
      (ethAddress, starkKey) => {
        handlePassMetamaskInfo(ethAddress, starkKey);
      },
      () => {
        // Handle the modal close event here
        console.log("Modal closed without connecting");
        handleFailedMetamaskInfo();
        // Add any additional logic for when the modal is closed
      }
    );
  };

  const [time, setTime] = useState(0);

  const handleGameOver = useCallback((time: number) => {
      console.log("handleGameOver");
      time = Math.round(time);
      setTime(time)
    },
    [time]
  );

  useEffect(() => {
    addEventListener("GameOver", handleGameOver);
    return () => {
      removeEventListener("GameOver", handleGameOver);
    };
  }, [handleGameOver, addEventListener, removeEventListener]);
  
  // useEffect is only run after every logic in App function is ran,
  // or [] dependencies are updated
  useEffect(() => {
    addEventListener("RequestMetaMaskAuthentication", handleRequestMetaMaskAuthentication);
    return () => {
      removeEventListener("RequestMetaMaskAuthentication", handleRequestMetaMaskAuthentication);
    };
  }, [handleRequestMetaMaskAuthentication, addEventListener, removeEventListener]);

  return (

    <div className={styles.container}>

      <ToastContainer hideProgressBar />

      <div className={styles.unityWrapper}>

      {isLoaded === false && (
        <>
          <div className={styles.loadingText}>
            <p>RIDICULOUSLY LOADING ({(loadingProgression * 110).toFixed(2)}%)</p>
          </div>
          <div className={styles.loadingBar}>
            <div
              className={styles.loadingBarFill}
              // 500 too few, 800 too much, 650 looks good,
              style={{ width: loadingProgression * 630 }}
            />
          </div>
        </>
      )}


        <Unity
          unityProvider={unityProvider}
          style={{ display: isLoaded ? "block" : "none" }}
        />
      </div>

    </div>
  );
};


export default App;
